import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const CommunityPage = () => {
  return (
    <Layout hero={<HeroImage title="National Centre for Farmer Health" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../../images/logo--ncfh.svg" 
                alt="Farmer health logo"
                width={251}
                height={82}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <p className="mb-3">
                Metalcorp has teamed up with the National Centre for Farmer Health (NCFH) 
                to make a difference to farmer's lives and give back to the rural community. 
                Our focus is on combatting the "Ripple Effect," preventing farmers from 
                taking their lives and stopping the enormous destruction this has on our rural communities.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center align-item-center blue-row-full content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={4} className="d-flex justify-content-center">
              <div className="title-container">
                <h3 className="title">Support Programs</h3>
              </div>
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <div className="title-container">
                <h3 className="title">Raise Awareness</h3>
              </div>
            </Col>
            <Col sm={4} className="d-flex justify-content-center">
              <div className="title-container">
                <h3 className="title">Inspire Change</h3>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../../images/workingwith.jpg" 
                alt="Working with farmers"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">PARTNERSHIPS AND FINANCIAL ASSISTANCE</h1>
              </div>
              <p className="mb-3">
                As a corporate partner, we are committed to raising awareness of the plight of farmers in rural Australia. 
                The NCFH's health promotion program is a way to create a supportive community and inspire change. 
                We will provide financial assistance to fund the NCFH's national leadership program, 
                which improves the wellbeing and safety of rural communities across Australia.
              </p>
              <Link className="button primary" to="/contact">
                Contact us
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center grey-row content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">
                  COMMUNITY FOCUSED METALCORP FIELD DAYS
                </h1>
              </div>
              <p className="mb-3">
                COVID-19 restrictions have resulted in cancellation of Field Days in 2020. 
                Metalcorp will be back to exhibiting at Field Days across Australia when they resume.
              </p>
              <Link className="button primary" to="/community/events-field-days">
                Events
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </Col>
            <Col sm={6}>
              <StaticImage 
                src="../../images/image-Ben-Matt.jpg" 
                alt="Ben Matt"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default CommunityPage

export const Head = () => <title>Australian Heritage | Metalcorp</title>
